@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .square {
        aspect-ratio: 1 / 1;
    }

    .skip-ink {
        text-decoration-skip-ink: none
    }

    .transition-all {
        transition: all 0.3s ease-in-out;
    }

    .font-gothampro-400 {
        font-family: 'GothamPro Regular', sans-serif;
        font-weight: 400;
    }

    .font-gothampro-500 {
        font-family: 'GothamPro Medium', sans-serif;
        font-weight: 500;
    }

    .font-gothampro-700 {
        font-family: 'GothamPro Bold', sans-serif;
        font-weight: 700;
    }

    .font-gothampro-900 {
        font-family: 'GothamPro Black', sans-serif;
        font-weight: 900;
    }
}


@font-face {
    font-family: 'GothamPro Regular';
    src: url('../../shared/assets/fonts/gothampro.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'GothamPro Medium';
    src: url('../../shared/assets/fonts/gothampro_medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'GothamPro Bold';
    src: url('../../shared/assets/fonts/gothampro_bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'GothamPro Black';
    src: url('../../shared/assets/fonts/gothampro_black.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
}

input:focus {
    outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

*::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

*::-webkit-scrollbar-track {
    background-color: #E2DFEB;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #C4BED3;
    border: 8px solid #C4BED3;
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}